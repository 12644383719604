export class MenuView {
  private menuList: Element;
  constructor(menuClassName: string) {
    this.menuList = document.querySelector(menuClassName);
  }
  onMenuClicked() {
    this.menuList.addEventListener("click", (e) => {
      e.preventDefault();
      const target = e.target as HTMLElement;
      const id = target.getAttribute("href");
      const element = document.querySelector(id);
      element.scrollIntoView({ behavior: "smooth" });
    });
  }

  public static run(menuClassName: string = ".menu__list") {
    return new MenuView(menuClassName).onMenuClicked();
  }
}
