import "core-js/stable";
import "regenerator-runtime/runtime";
import { MenuView } from "./views/menuView";
import { SectionReveal } from "./views/sectionView";
import { Map } from "./map";

const controlPageLoad = function () {
  SectionReveal.run();
  MenuView.run();
  Map.run();
};

controlPageLoad();
