import * as L from "leaflet";
import { config } from "./config";

export interface MapProps {
  mapId: string;
  latlon: L.LatLngExpression;
  zoomLevel: number;
}

export class Map {
  private props: MapProps;
  private map: L.Map;
  constructor(props: MapProps) {
    this.props = props;
  }

  showMap() {
    this.map = L.map(this.props.mapId).setView(
      this.props.latlon,
      this.props.zoomLevel
    );
    this.setTile();
    this.addMarker();
  }

  private setTile() {
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: "mapbox/streets-v11",
        tileSize: 512,
        zoomOffset: -1,
        accessToken: config.MAP_BOX_TOKEN,
      }
    ).addTo(this.map);
  }

  private addMarker() {
    const marker = L.marker(this.props.latlon).addTo(this.map);
    marker.bindPopup(config.MAP_MARKER_ADDRESS);
    marker.openPopup();
  }

  public static run(
    props: MapProps = {
      mapId: config.MAP_ID,
      latlon: config.MAP_LAT_LON as L.LatLngExpression,
      zoomLevel: config.MAP_ZOOM_LEVEL,
    }
  ) {
    return new Map(props).showMap();
  }
}
