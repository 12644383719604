export class SectionReveal {
  private sections: NodeListOf<Element>;
  private revealOptions: IntersectionObserverInit;
  constructor(
    sectionsClassName: string,
    revealOptions: IntersectionObserverInit
  ) {
    this.sections = document.querySelectorAll(sectionsClassName);
    this.revealOptions = revealOptions;
  }

  private revealSectionCallback(
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) {
    const [entry] = entries;
    if (!entry.isIntersecting) return;
    entry.target.classList.remove("section--hidden");
    observer.unobserve(entry.target);
  }

  activeSectionReveal() {
    const revealObserver = new IntersectionObserver(
      this.revealSectionCallback,
      this.revealOptions
    );
    this.sections.forEach((section) => {
      revealObserver.observe(section);
      section.classList.add("section--hidden");
    });
  }

  public static run(
    sectionsClassName: string = ".section",
    revealOptions: IntersectionObserverInit = {
      root: null,
      threshold: 0.15,
    }
  ) {
    return new SectionReveal(
      sectionsClassName,
      revealOptions
    ).activeSectionReveal();
  }
}
